
import { Options, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import TheoryQuestion from "@/components/Pages/Playground/Questions/TheoryQuestion.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";

@Options({
  components: { ForwardHeader, TheoryQuestion, DefaultLayout },
})
export default class Question extends Vue {
  public name = "Question";
}
