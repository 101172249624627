
import { Options, Prop } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import Question from "@/components/Pages/TheoryQuestions/Question.vue";
import { namespace } from "s-vuex-class";
import { mixins } from "vue-class-component";
import NavigationMixin from "@/mixins/NavigationMixin";
import QuestionAnswersMixin from "@/mixins/TheoryQuestions/QuestionAnswersMixin";
import isArray from "lodash/isArray";
import QuestionHint from "@/components/QuestionHint.vue";
import BackButton from "@/components/Pages/TheoryQuestions/BackButton.vue";
import NextButton from "@/components/NextButton.vue";
import FavoriteButton from "@/components/FavoriteButton.vue";
import PrevButton from "@/components/PrevButton.vue";
import FavoriteActionMixin from "@/mixins/FavoriteActionMixin";

const StudentEducationModule = namespace("student-education");
const TheoryQuestionsModule = namespace("theory-questions");
const StudentQuestionAnswersModule = namespace("student-question-answers");

@Options({
  components: {
    PrevButton,
    FavoriteButton,
    NextButton,
    BackButton,
    QuestionHint,
    Question,
    DefaultLayout,
  },
})
export default class TheoryQuestion extends mixins(NavigationMixin, QuestionAnswersMixin, FavoriteActionMixin) {
  public name = "TheoryQuestion";

  @Prop()
  public selectedQuestionId!: string | string[];

  @TheoryQuestionsModule.Action("findOne")
  public findQuestionAction: any;

  @TheoryQuestionsModule.Action("resetSave")
  public resetTheoryQuestionsQuestion: any;

  @TheoryQuestionsModule.Getter("getIsLoading")
  public loadingQuestion: any;

  @TheoryQuestionsModule.Getter("getDataItem")
  public question: any;

  @TheoryQuestionsModule.Action("setMarkFavouriteSuccess")
  public setMarkFavouriteSuccessAction: any;

  @StudentQuestionAnswersModule.Action("create")
  public answerAction: any;

  @StudentQuestionAnswersModule.Action("resetSave")
  public resetAnswerQuestion: any;

  @StudentQuestionAnswersModule.Getter("getSuccess")
  public answerSuccess: any;

  @StudentQuestionAnswersModule.Getter("getIsLoading")
  public loadingAnswer: any;

  @StudentEducationModule.Getter("withTheory/getDataList")
  public studentEducations: any;

  @StudentEducationModule.Getter("withTheory/getIsLoading")
  public studentEducationsLoading: any;

  private showSolutionButton = false;
  private showCorrectAnswers = false;

  private selectedQuestionIndex = 0;

  private get theoryQuestionIds() {
    return Array.isArray(this.selectedQuestionId) ? this.selectedQuestionId : [this.selectedQuestionId];
  }

  mounted() {
    this.$watch(
      () => this.studentEducations,
      (studentEducations: any) => {
        if (isArray(studentEducations) && studentEducations.length > 0) {
          this.setStudentEducation();
          this.loadQuestionById(Number(this.theoryQuestionIds[0]));
        }
      },
      { immediate: true, deep: true }
    );
  }

  setStudentEducation(): any {
    this.studentEducation = this.studentEducations[0].id;
  }

  get loading(): boolean {
    return this.loadingQuestion || this.loadingAnswer;
  }

  private loadQuestionById(id: number): void {
    this.findQuestionAction({
      resource: "theory-questions",
      id: id,
      hideSuccessMessage: true,
      params: { studentEducationId: this.studentEducation },
    });
  }

  private showSolutions(): void {
    this.showSolutionButton = false;
    this.showCorrectAnswers = true;
  }

  private hideSolutions(): void {
    this.showCorrectAnswers = false;
  }

  private reset(): void {
    this.resetTheoryQuestionsQuestion();
    this.setMarkFavouriteSuccessAction(null);
  }

  private goToNext(): void {
    if (this.selectedQuestionIndex >= this.theoryQuestionIds.length - 1) {
      return;
    }
    this.hideSolutions();
    this.selectedQuestionIndex = this.selectedQuestionIndex + 1;
    this.loadQuestionById(Number(this.theoryQuestionIds[this.selectedQuestionIndex]));
  }

  private goToPrev(): void {
    if (this.selectedQuestionIndex <= 0) {
      return;
    }
    this.hideSolutions();
    this.selectedQuestionIndex = this.selectedQuestionIndex - 1;
    this.loadQuestionById(Number(this.theoryQuestionIds[this.selectedQuestionIndex]));
  }
}
